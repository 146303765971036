/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect, useState} from '@supermove/hooks';

import {SSEContext, SSEContextValue} from '@shared/modules/SSE/context/SSEContext';
import {SSEChannelSubscriptionRequest, SSEEvent} from '@shared/modules/SSE/interfaces';

const filterEvents = (
  events: SSEEvent[],
  eventTypes: string[],
  channels: SSEChannelSubscriptionRequest[],
) => {
  return events.filter(
    (event) =>
      eventTypes.includes(event.eventType) &&
      (channels.length === 0 ||
        channels.some(
          (channel) =>
            channel.channelType === event.channelType && channel.channelId === event.channelId,
        )),
  );
};

export const useSSE = (
  eventTypes: string[] = [],
): SSEContextValue & {latestEvent: SSEEvent | null} => {
  const [latestEvent, setLatestEvent] = useState<SSEEvent | null>(null);
  const [filteredEvents, setFilteredEvents] = useState<SSEEvent[]>([]);
  const [channels, setChannels] = useState<SSEChannelSubscriptionRequest[]>([]);
  const sseContext = useContext(SSEContext);

  const {events, subscribe, unsubscribe, subscribedChannels, connected} = sseContext;
  useEffect(() => {
    const filteredEvents = eventTypes ? filterEvents(events, eventTypes, channels) : events;
    setFilteredEvents(filteredEvents);

    const latestEventChanged =
      filteredEvents[filteredEvents.length - 1]?.eventId !== latestEvent?.eventId;
    if (filteredEvents.length > 0 && latestEventChanged) {
      setLatestEvent(filteredEvents[filteredEvents.length - 1]);
    }
  }, [events]);

  return {
    events: filteredEvents,
    subscribe: (channels: SSEChannelSubscriptionRequest[]) => {
      setChannels(channels);
      subscribe(channels);
    },
    unsubscribe,
    latestEvent,
    subscribedChannels,
    connected,
    flushEvents: sseContext.flushEvents,
  };
};
